<template lang="pug">
b-modal#modal-blockWordSet(title="新增封鎖詞", title-class="font-18", body-class="p-4" size="lg")
  .row.align-items-end(v-for="(list,index) in add_list")
    .col-5
      .form-group.mb-2
        label 封鎖詞
        br
        input.form-control(v-model="list.value", type="text", placeholder="請輸入封鎖詞")
    .col-5
      .form-group.mb-2
        label 狀態
        br
        multiselect(v-model="list.status", :options="options", placeholder="請選擇狀態", select-label="")
    .col.mb-1(v-if="index > 0")
      .del-btn.mb-2(@click="deleteOne(index)")
        b-button.btn(href="javascript:void(0);" size="sm", variant="primary")
          i.mdi.mdi-close 
  .add-new.mt-1
    b-button.btn.ml-0.mt-0.width-md(size="md", variant="outline-dark" @click="addNewType()") ＋新增

</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      value:'',
      options: [
        "不啟用",
        "啟用",
      ],
      add_list:[
        {
          value:'',
          status:''
        }
      ]
    }
  },
  methods:{
    addNewType(){
      const pass = {
        value:'',
        status:''
      }
      this.add_list.push(pass)
    },
    deleteOne(_index){
      this.add_list.splice(_index,1)
    }
  },
  components: {
    Multiselect
  },
}
</script>
<style lang="sass" scoped>
.addbtn
  border: 1px solid #000
</style>
