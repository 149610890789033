<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { tableData } from "./data";
import blockWordSet from "@/components/blockWordSet";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "封鎖詞管理",
      items: [
        {
          text: "留言/聊天室管理",
          href: "/msgmanagement",
        },
        {
          text: "封鎖詞管理",
          active: true,
        }
      ],
      search_data:{
        status: 0,
      },
      value:'',
      daterange:'',
      visible: true,

      tableData: tableData,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "封鎖詞",
          sortable: false,
        },
        {
          key: "狀態",
          sortable: false,
        },
        {
          key: "建立時間",
          sortable: false,
        },
        {
          key: "操作",
          sortable: false,
        },
      ],
    };
  },
  computed:{
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    blockWordSet

  },
  methods:{
    changeTab(_status){
      this.tab = _status
    }
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  blockWordSet
  .row
    .col-lg-12
      //- Table
      .card
        .col-lg-12
          b-collapse#customaccordion-1(v-model="visible", accordion="accordion", role="tabpanel")
            b-card-body
              form.form-horizontal.row.d-flex.align-items-end(role="form")
                .form-group.mb-0
                  label 封鎖詞查詢
                  input.form-control(v-model="option", type="text")

                .mt-3.ml-2
                  b-button.width-md(variant="primary") 查詢
                  
      //-表格
      table.col-12
        .card
          .card-body
            .header-row.mb-3.d-flex.justify-content-end.align-items-start
              .header-title 封鎖詞查詢結果
              b-button.width-md(variant="primary"  v-b-modal.modal-blockWordSet) ＋ 新增封鎖詞
            .table-responsive.mb-0
              b-table(:items="tableData", :fields="fields", responsive="sm", :per-page="perPage", :current-page="currentPage", :sort-desc.sync="sortDesc", :filter="filter", :filter-included-fields="filterOn", @filtered="onFiltered")
            .row.mb-md-2
              .col-6
                div(id="tickets-table_length", class="dataTables_length")
                  label.d-inline-flex.align-items-center
                    | Show
                    b-form-select.ml-2.mr-2(v-model="perPage", size="sm", :options="pageOptions")
                    | entries
              .col-6
                div(class="dataTables_paginate paging_simple_numbers float-right")
                  ul.pagination.pagination-rounded.mb-0
                    // pagination
                    b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")
</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
.header-row
  display: flex
  justify-content: space-between
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
</style>
